

.tree-Apple {
    background-image: url('images/fruits/Apple.svg');
}

.tree-Pear {
    background-image: url('images/fruits/Pear.svg');
}

.tree-Cherry {
    background-image: url('images/fruits/Cherry.svg');
}

.tree-Plum {
    background-image: url('images/fruits/Plum.svg');
}

.tree-Peach {
    background-image: url('images/fruits/Peach.svg');
}

.tree-Apricot {
    background-image: url('images/fruits/Apricot.svg');
}
.tree-Pomegranate {
    background-image: url('images/fruits/Pomegranate.svg');
}

.tree-Lemon {
    background-image: url('images/fruits/Lemon.svg');
}

.tree-Lime {
    background-image: url('images/fruits/Lime.svg');
}

.tree-Mango {
    background-image: url('images/fruits/Mango.svg');
}

.tree-Orange {
    background-image: url('images/fruits/Orange.svg');
}

.tree-Grapefruit {
    background-image: url('images/fruits/Grapefruit.svg');
}