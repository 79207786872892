html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Verdana", sans-serif;
  background-color: #f0f0f0;
  color: #333;
  margin: 0;
  padding: 0;
  background-image: url('images/bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.App {
  text-align: center;
  padding: 20px;
}

h1 {
  font-size: 2em;
  margin-bottom: 0.5em;
}

p {
  font-size: 1.2em;
  margin: 0.5em 0;
}

.tree-selection, .plantations {
  position:fixed;
  top:45%;
  width: 400px;
}

.tree-options {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.tree {
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 10px;
  margin: 5px;
  cursor: pointer;
  transition: transform 0.2s;
  height:50px;
  width:40px;
  background-size:40px;
  background-repeat: no-repeat;
  background-position: center 5px;
}

.tree:hover {
  transform: scale(1.05);
}
.tree span{
  position:relative;
  top: 35px;
}

.plantation {
  float:left;
  min-height:140px;
  width: 40%;
  background-image: url('images/layer/0.png');
  background-size: 100% 100%;
  padding: 3px;
  margin: 3px;
  cursor: pointer;
  transition: transform 0.2s;
}

.plantation.plantation-1{
  float:left;
  min-height:100px;
  width: 40%;
  background-image: url('images/layer/1.png');
  background-size: 100% 100%;
  padding: 3px;
  margin: 3px;
  cursor: pointer;
  transition: transform 0.2s;
}

.plantation.plantation-2{
  float:left;
  min-height:100px;
  width: 40%;
  background-image: url('images/layer/2.png');
  background-size: 100% 100%;
  padding: 3px;
  margin: 3px;
  cursor: pointer;
  transition: transform 0.2s;
}

.plantation:hover {
  transform: scale(1.05);
}

.plantation h3 {
  margin: 0.5em 0;
  font-size: 1.2em;
}

.plantation p {
  margin: 0.3em 0;
}


.header {
  background: #e0f7fa;
  border-radius: 50px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-family: 'Arial', sans-serif;
  color: #333;
  text-align: center;
  position: relative;
  overflow: hidden;
}
.money-icon {
  background-image: url('images/money.png');
  width:24px;
  height:24px;
}

.footer{
  position: fixed;
  bottom: 20px;
  width: 85%;
  left: 5%;
  height:55px;
  padding:2.5%;
  border-radius:15px;
  background-color: RGBA(255,255,255, 0.3);
}
.footer ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}

.footer ul li {
  margin: 0 5px;
}

.footer ul li{
  transition: background 0.3s;
  border-radius: 5px;
  cursor:pointer
}

.footer ul li:hover {
  background: rgba(173, 216, 230, 0.4);
}
.footer img {
  height:36px;
}

/* src/components/Modal.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-overlay-inner {
  z-index:9999999;
}

.modal {
  background: white;
  padding: 2.5%;
  border-radius: 5px;
  width: 80%;
  margin-left:5%;
}

.close-button {
  background:none;
  color:black;
  border: none;
  font-size: 1.5rem;
  position: absolute;
  margin-top:-10px;
  right: 5%;
  cursor: pointer;
}

.modal-content {
  margin-top: 10px;
}


.footer ul.store {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Это создает 4 колонки одинаковой ширины */
  gap: 10px;
  list-style: none;
  padding: 0;
}
.buy_new_one {
  padding:25px;
  background-color:green;
  color:white;
  border-radius:25px !important;
}
.buy_new_one  span {
  font-weight:bold;
}

.plantation-tree {
  min-height:80px;
  background-size: 40px;
  background-position: center top;
  background-repeat: no-repeat;
}

.plantationTimer {
  padding-top:50px;
  color:white;
}




/* Styles for input fields */
input[type="text"],
input[type="email"],
input[type="password"],
textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s ease-in-out;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus,
textarea:focus {
  border-color: #80bdff;
  outline: none;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.25);
}

/* Input group styles */
.input-group {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.input-group input {
  border-radius: 0 4px 4px 0;
  margin-bottom: 0;
}

.input-group .input-group-prepend,
.input-group .input-group-append {
  display: flex;
}

.input-group .input-group-prepend .input-group-text,
.input-group .input-group-append .input-group-text {
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 4px 0 0 4px;
  padding: 10px 12px;
  color: #495057;
  font-size: 16px;
}

/* Styles for buttons */
button.default {
  display: inline-block;
  font-weight: 400;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 4px;
  transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
  cursor: pointer;
  width: 100%;
}

button.default:hover {
  background-color: #0056b3;
  border-color: #004085;
}

button.default:focus {
  outline: none;
}


@keyframes appearAndFlyDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  50% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(100vh); /* Adjust this value as needed */
  }
}

.icon-animation {
  animation: appearAndFlyDown 3s ease-in-out forwards;
  width: 50px; /* Adjust as needed */
  height: 50px; /* Adjust as needed */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.col-6 {
  position: relative;
  width: 50%;
  padding-right: 15px;
  padding-left: 15px;
}

.col-12 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.text-left {
  text-align: left;
}

.shopBuyIcon {
  height: 60px;
  width: 20%;
  background-size: 55px;
  background-repeat: no-repeat;
  background-position: center center;
}

.shopBuyLeft {
  width:60%;
}

.quantity-input {
  width: 40px;
  padding: 0px 5px;
  text-align: center;
}
.quantity-selector input, .quantity-selector button {
  font-size:24px;
}
